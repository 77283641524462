<template>
  <v-row class="ma-0">
    <v-col cols="4.5">
      <div
        :id="`card-screenshot${screenshotId ? `-${screenshotId}` : ''}`"
        style="
          display: flex;
          max-width: 404px;
          height: 250px;
          border-radius: 20px;
        "
      >
        <v-img
          max-width="384"
          max-height="240"
          ref="draggableContainer"
          id="draggable-container"
          :style="`background-color: ${toHex(this.cardData.design.bgColor)}`"
          :src="
            backgroundCardImage
              ? backgroundCardImage.previewUrl
              : cardData.design.bgUrl
          "
        >
          <div
            :style="`padding: 10px; width: 100%; height: 100%; ${
              cardData.design.showOverlay
                ? `background-color: rgba(${
                    cardData.design.showOverlay == 'dark'
                      ? '0, 0, 0'
                      : '255, 255, 255'
                  }, ${cardData.design.overlayIntensity / 100});`
                : ''
            }`"
          >
            <div
              id="name"
              :class="`draggable-element ${
                selectedNode == 'name' ? 'active' : ''
              }`"
              :style="styleElement('name')"
              @click="($event) => selectElement($event, 'name')"
            >
              {{ user ? cardData.name : currentUser.name }}
            </div>
            <div
              id="phoneNumbers"
              :class="`draggable-element ${
                selectedNode == 'phoneNumbers' ? 'active' : ''
              }`"
              :style="styleElement('phoneNumbers')"
              @click="($event) => selectElement($event, 'phoneNumbers')"
            >
              {{
                user
                  ? cardData.phoneNumbers && cardData.phoneNumbers.length > 0
                    ? cardData.phoneNumbers[0].phone +
                      (cardData.phoneNumbers.length > 1
                        ? ` (+${cardData.phoneNumbers.length - 1})`
                        : '')
                    : ''
                  : '+91987654321'
              }}
            </div>
            <div
              id="emails"
              :class="`draggable-element ${
                selectedNode == 'emails' ? 'active' : ''
              }`"
              :style="styleElement('emails')"
              @click="($event) => selectElement($event, 'emails')"
            >
              {{
                user
                  ? cardData.emails && cardData.emails.length > 0
                    ? cardData.emails[0] +
                      (cardData.emails.length > 1
                        ? ` (+${cardData.emails.length - 1})`
                        : '')
                    : ''
                  : currentUser.email
              }}
            </div>
            <v-img
              id="logoUrl"
              v-if="logoCardImage || cardData.design.logoUrl"
              :width="
                (cardData.elementDesigns.logoUrl.fontSize * (240 - 40)) / 100
              "
              :height="
                (cardData.elementDesigns.logoUrl.fontSize * (240 - 40)) / 100
              "
              :max-width="
                (cardData.elementDesigns.logoUrl.fontSize * (240 - 40)) / 100
              "
              :max-height="
                (cardData.elementDesigns.logoUrl.fontSize * (240 - 40)) / 100
              "
              elevation="1"
              :class="`draggable-element ${
                selectedNode == 'logoUrl' ? 'active' : ''
              }`"
              :style="styleElement('logoUrl')"
              :src="
                logoCardImage
                  ? logoCardImage.previewUrl
                  : cardData.design.logoUrl
              "
              @click="($event) => selectElement($event, 'logoUrl')"
            >
            </v-img>
            <div
              id="designation"
              :class="`draggable-element ${
                selectedNode == 'designation' ? 'active' : ''
              }`"
              :style="styleElement('designation')"
              @click="($event) => selectElement($event, 'designation')"
            >
              {{ user ? `${cardData.designation || ''}` : 'designation' }}
            </div>
            <div
              id="qualification"
              :class="`draggable-element ${
                selectedNode == 'qualification' ? 'active' : ''
              }`"
              :style="styleElement('qualification')"
              @click="($event) => selectElement($event, 'qualification')"
            >
              {{ user ? `${cardData.qualification || ''}` : 'qualification' }}
            </div>
            <div
              id="company"
              :class="`draggable-element ${
                selectedNode == 'company' ? 'active' : ''
              }`"
              :style="styleElement('company')"
              @click="($event) => selectElement($event, 'company')"
            >
              {{
                user
                  ? cardData.company
                  : cardData.cardCompanyName || cardData.name
              }}
            </div>
            <div
              id="address"
              :class="`draggable-element ${
                selectedNode == 'address' ? 'active' : ''
              }`"
              :style="styleElement('address')"
              @click="($event) => selectElement($event, 'address')"
            >
              {{
                cardData.address && cardData.address.length > 0
                  ? cardData.address[0].address +
                    (cardData.address.length > 1
                      ? ` (+${cardData.address.length - 1})`
                      : '')
                  : ''
              }}
            </div>
          </div>
        </v-img>
      </div>

      <p class="mt-3 mb-0 mx-5 text-caption">
        Tap inside card to select and deselect detail/element.
      </p>
      <p class="mx-5 text-caption">
        eg. select " {{ user ? cardData.name : currentUser.name }} " and change
        position.
      </p>
    </v-col>
    <v-col cols="3.5">
      <div v-if="!disableFileInput">
        <h4>Choose Card Background Image</h4>
        <p class="text-caption">
          Image should be 16:10 ratio size Eg. 384 Width & 240 Height
        </p>
        <input
          class="mb-2"
          type="file"
          accept="image/*"
          max-file-size="1000"
          @change="(event) => $emit('inputClickFileSelector', event, 'bg')"
        />
        <v-icon
          v-if="backgroundCardImage || cardData.design.bgUrl"
          small
          @click="
            {
              $emit('inputClickFileSelector', null, 'bg');
            }
          "
        >
          fa-trash-alt
        </v-icon>
      </div>
      <v-row class="ma-0">
        <div class="mt-2 mr-3">
          <h5>Background Color</h5>
          <input type="color" v-model="cardBackgroundColor" />
        </div>

        <v-checkbox
          v-model="cardData.design.enableShadow"
          label="Text Shadow"
        ></v-checkbox>
      </v-row>
      <template v-if="selectedNode">
        <!-- <v-row align="center" justify="center" class="mx-0 flex-nowrap">
          <v-text-field
            type="number"
            :value="cardData.elementDesigns[selectedNode].top"
            @input="changeTopPosition"
            label="Top"
            suffix="%"
          ></v-text-field>
          <h4 class="mx-2">OR</h4>
          <v-text-field
            type="number"
            :value="cardData.elementDesigns[selectedNode].bottom"
            @input="changeBottomPosition"
            label="Bottom"
            suffix="%"
          ></v-text-field>
        </v-row>
        <v-row align="center" justify="center" class="mx-0 flex-nowrap">
          <v-text-field
            type="number"
            :value="cardData.elementDesigns[selectedNode].left"
            @input="changeLeftPosition"
            label="Left"
            suffix="%"
          ></v-text-field>
          <h4 class="mx-2">OR</h4>
          <v-text-field
            type="number"
            :value="cardData.elementDesigns[selectedNode].right"
            @input="changeRightPosition"
            label="Right"
            suffix="%"
          ></v-text-field>
        </v-row> -->

        <v-row align="center" justify="center" class="mx-0 flex-nowrap">
          <div
            class="text-subtitle-1 font-weight-light"
            style="width: 70px; text-align: left"
          >
            Vertical
          </div>
          <v-slider
            max="100"
            min="0"
            hide-details
            :value="cardData.elementDesigns[selectedNode].top"
            @change="changeTopPosition"
          ></v-slider>
          <div
            class="text-subtitle-1 font-weight-light"
            style="width: 25px; text-align: right"
          >
            {{
              `${
                cardData.elementDesigns[selectedNode].top != null &&
                cardData.elementDesigns[selectedNode].top >= 0
                  ? cardData.elementDesigns[selectedNode].top
                  : ''
              }%`
            }}
          </div>
        </v-row>
        <!-- <h4 class="my-2">OR</h4>
        <v-row align="center" justify="center" class="mx-0 flex-nowrap">
          <div
            class="text-subtitle-1 font-weight-light"
            style="width: 50px; text-align: left"
          >
            Bottom
          </div>
          <v-slider
            max="100"
            min="-1"
            hide-details
            :value="cardData.elementDesigns[selectedNode].bottom"
            @change="changeBottomPosition"
          ></v-slider>
          <div
            class="text-subtitle-1 font-weight-light"
            style="width: 25px; text-align: right"
          >
            {{
              `${
                cardData.elementDesigns[selectedNode].bottom != null &&
                cardData.elementDesigns[selectedNode].bottom >= 0
                  ? cardData.elementDesigns[selectedNode].bottom
                  : ''
              }%`
            }}
          </div>
        </v-row> -->
        <div class="my-4"></div>
        <v-row align="center" justify="center" class="mx-0 flex-nowrap">
          <div
            class="text-subtitle-1 font-weight-light"
            style="width: 70px; text-align: left"
          >
            Horizontal
          </div>
          <v-slider
            max="100"
            min="0"
            hide-details
            :value="cardData.elementDesigns[selectedNode].left"
            @change="changeLeftPosition"
          ></v-slider>
          <div
            class="text-subtitle-1 font-weight-light"
            style="width: 25px; text-align: right"
          >
            {{
              `${
                cardData.elementDesigns[selectedNode].left != null &&
                cardData.elementDesigns[selectedNode].left >= 0
                  ? cardData.elementDesigns[selectedNode].left
                  : ''
              }%`
            }}
          </div>
        </v-row>
        <!-- <h4 class="my-2">OR</h4>
        <v-row align="center" justify="center" class="mx-0 flex-nowrap">
          <div
            class="text-subtitle-1 font-weight-light"
            style="width: 50px; text-align: left"
          >
            Right
          </div>
          <v-slider
            max="100"
            min="-1"
            hide-details
            :value="cardData.elementDesigns[selectedNode].right"
            @change="changeRightPosition"
          ></v-slider>
          <div
            class="text-subtitle-1 font-weight-light"
            style="width: 25px; text-align: right"
          >
            {{
              `${
                cardData.elementDesigns[selectedNode].right != null &&
                cardData.elementDesigns[selectedNode].right >= 0
                  ? cardData.elementDesigns[selectedNode].right
                  : ''
              }%`
            }}
          </div>
        </v-row> -->
        <div class="mt-9"></div>

        <!-- <v-menu
          v-model="colorPanelMenu"
          :close-on-content-click="false"
          :max-width="240"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              :value="
                toHex(cardData.elementDesigns[selectedNode].color, true)
              "
              label="Text Color"
              :append-icon="`${
                !colorPanelMenu ? 'fa-angle-down' : 'fa-angle-up'
              }`"
            ></v-text-field>
          </template>

          <v-color-picker
            v-if="colorPanelMenu"
            dot-size="25"
            mode="hexa"
            swatches-max-height="200"
            :value="toHex(cardData.elementDesigns[selectedNode].color, true)"
            @update:color="($event) => changeColor($event)"
          ></v-color-picker>
        </v-menu> -->
      </template>

      <v-select
        class="mt-4"
        v-model="cardData.design.showOverlay"
        :items="showOverlayOptions"
        item-text="label"
        item-value="value"
        label="Color Overlay on card background"
      ></v-select>

      <div v-if="cardData.design.showOverlay" class="mt-2">
        <label>
          Overlay Intensity - Transparency ({{
            parseInt(100 - (cardData.design.overlayIntensity / 255) * 100, 10)
          }}%)
        </label>
        <v-slider
          max="255"
          min="0"
          v-model="cardData.design.overlayIntensity"
        ></v-slider>
      </div>
    </v-col>
    <v-col cols="3.5">
      <div>
        <div v-if="!disableFileInput">
          <h4>Choose Card Logo Image</h4>
          <input
            type="file"
            accept="image/*"
            max-file-size="1000"
            @change="(event) => $emit('inputClickFileSelector', event, 'logo')"
          />
          <v-icon
            v-if="logoCardImage || cardData.design.logoUrl"
            small
            @click="
              {
                $emit('inputClickFileSelector', null, 'logo');
              }
            "
          >
            fa-trash-alt
          </v-icon>
        </div>
        <div v-if="!disableFileInput">
          <h4 class="mt-10">Choose Custom Font</h4>
          <input
            class="mb-10"
            type="file"
            accept=".ttf"
            max-file-size="1000"
            @change="(event) => $emit('inputClickFileSelector', event, 'font')"
          />
          <v-icon
            v-if="customFont || cardData.design.fontUrl"
            small
            @click="
              {
                $emit('inputClickFileSelector', null, 'font');
              }
            "
          >
            fa-trash-alt
          </v-icon>
        </div>

        <template v-if="selectedNode">
          <v-row class="ma-0">
            <div class="mt-3 mr-5 mb-5">
              <h5>Text Color</h5>
              <input type="color" v-model="textColor" />
            </div>
            <v-checkbox
              v-model="cardData.elementDesigns[selectedNode].bold"
              label="Text Bold"
            ></v-checkbox>
            <v-select
              :items="textAlignmentOptions"
              v-model="cardData.elementDesigns[selectedNode].textAlignment"
              label="Text Alignment"
              item-text="label"
              item-value="value"
              style="margin-left: 20px; max-width: 90px"
            ></v-select>
          </v-row>
          <v-text-field
            type="number"
            :value="cardData.elementDesigns[selectedNode].fontSize"
            @input="changeFontSizePosition"
            :label="selectedNode == 'logoUrl' ? 'Image Size' : 'Text Size'"
            suffix="%"
          ></v-text-field>
        </template>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'CardDraggable',
  props: [
    'cardData',
    'user',
    'backgroundCardImage',
    'logoCardImage',
    'customFont',
    'disableFileInput',
    'screenshotId',
  ],
  data() {
    return {
      selectedTarget: null,
      colorPanelMenu: false,
      textAlignmentOptions: [
        {
          value: 0,
          label: 'left',
        },
        {
          value: 2,
          label: 'center',
        },
        {
          value: 1,
          label: 'right',
        },
      ],
      showOverlayOptions: [
        {
          value: '',
          label: 'None',
        },
        {
          value: 'light',
          label: 'White',
        },
        {
          value: 'dark',
          label: 'Black',
        },
      ],
    };
  },
  created() {
    this.setSelectedNode(null);
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user,
      selectedNode: (state) => state.selectedNode,
    }),
    textColor: {
      get() {
        return this.toHex(
          this.cardData.elementDesigns[this.selectedNode].color
        );
      },
      set(color) {
        if (!color) return;
        const intColor = parseInt(color.substr(1), 16);
        // console.log(color, intColor, this.toHex(intColor));
        this.cardData.elementDesigns[this.selectedNode].color = intColor;
      },
    },
    cardBackgroundColor: {
      get() {
        return this.toHex(this.cardData.design.bgColor);
      },
      set(color) {
        if (!color) return;
        const intColor = parseInt(color.substr(1), 16);
        console.log(color, intColor, this.toHex(intColor));
        this.cardData.design.bgColor = intColor;
      },
    },
  },
  methods: {
    ...mapMutations(['setSelectedNode']),
    styleElement(node) {
      const e = this.cardData.elementDesigns[node];

      const padding = 10;

      const cardWidth = 384 - padding;
      const cardHeight = 240 - padding;

      const colVal = this.toHex(e.color, true);
      const topVal = (e.top * cardHeight) / 100;
      const leftVal = (e.left * cardWidth) / 100;
      const rightVal = (e.right * cardWidth) / 100;
      const bottomVal = (e.bottom * cardHeight) / 100;
      // console.log('e.color', e?.color, colVal);
      const fontSize = `font-size: ${e.fontSize}px;`;
      const fontBold = e.bold ? `font-weight: bold;` : '';
      const xAxis =
        e.top == null && e.bottom >= 0
          ? `bottom: ${bottomVal}px;`
          : `top: ${topVal + padding}px;`;
      const yAxis =
        e.left == null && e.right >= 0
          ? `margin: 0px 10px; right: ${rightVal}px;`
          : `margin: 0px 0px; left: ${leftVal + padding}px;`;
      // console.log(node, yAxis, xAxis);
      const color = `color: ${colVal};`;
      // const showTextColor = this.cardData.design.enableShadow
      //   ? `text-shadow: 1px 1px 2px rgb(0, 0, 0 / 55%);`
      //   : '';
      const showTextColor = this.cardData.design.enableShadow
        ? `text-shadow: rgb(0 0 0 / 45%) 1px 1px 2px;`
        : '';
      const borderRadius =
        node === 'logoUrl'
          ? `border-radius: ${
              this.cardData.elementDesigns['logoUrl']?.fontSize || 30
            }px; background-color: transparent;`
          : '';
      const textAlign = `text-align: ${
        this.textAlignmentOptions.find(
          (e) => e.value == this.cardData.elementDesigns[node]?.textAlignment
        ).label
      };`;
      const noWrap = node == 'address' ? '' : 'white-space: nowrap;';
      // const translate = `transform: translate(${leftVal}px, ${topVal}px);`;
      return `${fontSize} ${fontBold} ${xAxis} ${yAxis} ${color} ${showTextColor} ${textAlign} ${borderRadius} ${noWrap}`;
    },
    toHex(d, subs) {
      // const color = 0xff123456;
      // const a = color >>> 24; // 0xff
      // const r = (color >>> 16) & 0xff; // 0x12
      // const g = (color >>> 8) & 0xff; // 0x34
      // const b = color & 0xff; // 0x56
      const hv = ('000000' + (d + 16777216).toString(16)).slice(-6); //d.toString(16);
      // console.log(hv);
      return `#${hv}`;
    },
    selectElement(event, node) {
      this.selectedTarget = event.target;
      if (this.selectedNode == node) {
        this.setSelectedNode(null);
      } else {
        this.setSelectedNode(node);
      }
    },
    changeLeftPosition(left) {
      this.calculatePosition(
        left == -1 ? null : left,
        this.cardData.elementDesigns[this.selectedNode].top,
        left == -1 ? 0 : null,
        this.cardData.elementDesigns[this.selectedNode].bottom
      );
    },
    changeTopPosition(top) {
      this.calculatePosition(
        this.cardData.elementDesigns[this.selectedNode].left,
        top == -1 ? null : top,
        this.cardData.elementDesigns[this.selectedNode].right,
        top == -1 ? 0 : null
      );
    },
    changeRightPosition(right) {
      this.calculatePosition(
        right == -1 ? 0 : null,
        this.cardData.elementDesigns[this.selectedNode].top,
        right == -1 ? null : right,
        this.cardData.elementDesigns[this.selectedNode].bottom
      );
    },
    changeBottomPosition(bottom) {
      this.calculatePosition(
        this.cardData.elementDesigns[this.selectedNode].left,
        bottom == -1 ? 0 : null,
        this.cardData.elementDesigns[this.selectedNode].right,
        bottom == -1 ? null : bottom
      );
    },
    changeFontSizePosition(fontSize) {
      this.cardData.elementDesigns[this.selectedNode].fontSize = fontSize;
    },
    calculatePosition(left, top, right, bottom) {
      // console.log(left, top, right, bottom);
      const padding = 10;

      const cardWidth = 384 - padding;
      const cardHeight = 240 - padding;

      const elementWidth =
        this.selectedTarget && this.selectedTarget.id === this.selectedNode
          ? this.selectedTarget.clientWidth
          : 0;
      const gettingWidth = ((right || left) * cardWidth) / 100;
      const expectedWidth = cardWidth - elementWidth - (padding + padding / 2);
      if (gettingWidth >= expectedWidth) {
        const setVal = ((expectedWidth / cardWidth) * 100).toFixed(2);
        if (right) {
          this.cardData.elementDesigns[this.selectedNode].right =
            setVal < 0 ? 0 : setVal;
        } else {
          this.cardData.elementDesigns[this.selectedNode].left =
            setVal < 0 ? 0 : setVal;
        }
      } else {
        if (right) {
          this.cardData.elementDesigns[this.selectedNode].right =
            right < 0 ? 0 : right;
        } else {
          this.cardData.elementDesigns[this.selectedNode].left =
            left < 0 ? 0 : left;
        }
        // this.cardData.elementDesigns[this.selectedNode].left = left
        //   ? Number(left)
        //   : left == 0
        //   ? 0
        //   : null;
        // this.cardData.elementDesigns[this.selectedNode].right = right
        //   ? Number(right)
        //   : right == 0
        //   ? 0
        //   : null;
      }

      const elementHeight =
        this.selectedTarget && this.selectedTarget.id === this.selectedNode
          ? this.selectedTarget.clientHeight
          : 0;
      const gettingHeight = ((bottom || top) * cardHeight) / 100;
      const expectedHeight =
        cardHeight - elementHeight - (padding + padding / 2);
      if (gettingHeight >= expectedHeight) {
        const setVal = ((expectedHeight / cardHeight) * 100).toFixed(2);
        if (bottom) {
          this.cardData.elementDesigns[this.selectedNode].bottom = setVal;
        } else {
          this.cardData.elementDesigns[this.selectedNode].top = setVal;
        }
      } else {
        if (bottom) {
          this.cardData.elementDesigns[this.selectedNode].bottom = bottom;
        } else {
          this.cardData.elementDesigns[this.selectedNode].top = top;
        }
        // this.cardData.elementDesigns[this.selectedNode].top = top
        //   ? Number(top)
        //   : top == 0
        //   ? 0
        //   : null;
        // this.cardData.elementDesigns[this.selectedNode].bottom = bottom
        //   ? Number(bottom)
        //   : bottom == 0
        //   ? 0
        //   : null;
        // this.$forceUpdate();
      }
    },
    onDragStart(e) {
      console.log('onDragStart ', e);
    },
    onDragEnd(e) {
      console.log(e.target);
      if (('onDragEnd ', e?.target?.style))
        e.target.style.position = 'absolute';
    },
    onDrop(e) {
      console.log('onDrop ', e);
    },
  },
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#draggable-container {
  // border: 1px solid grey;
  border-radius: 20px;
  width: 384px;
  height: 240px;
  box-shadow: 0 0 2px 1px #e0e0e0;
  margin: 5px 10px;
  // padding: 10px;
}
.draggable-element {
  position: absolute;

  &.active {
    border: 1px solid blue;
  }
}
</style>
